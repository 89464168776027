<template>
  <q-card class="my-card text-left q-ma-md q-pb-sm hola1">
    <q-list>
      <q-item >
        <q-item-section>
          <q-item-label class="text-weight-bold">{{ $t("Profile.personal-data.my-data") }}</q-item-label>
        </q-item-section>
        <q-item-section avatar>
          <q-icon color="primary" class="cursor-pointer icon-white" name="edit" @click="dialog = true" />
        </q-item-section>
      </q-item>

      <q-item class="q-ml-md">
        <q-item-section>
          <q-item-label caption>{{
            $t("Profile.personal-data.email")
          }}</q-item-label>
          <q-item-label>{{ user.email }}</q-item-label>
        </q-item-section>
      </q-item>

      <q-item class="q-ml-md">
        <q-item-section>
          <q-item-label caption>{{
            $t("Profile.personal-data.name")
          }}</q-item-label>

          <q-item-label>{{ user.name }}</q-item-label>
        </q-item-section>
      </q-item>

      <q-item class="q-ml-md">
        <q-item-section>
          <q-item-label caption>{{
            $t("Profile.personal-data.surname")
          }}</q-item-label>
          <q-item-label >{{
            user.last_name
          }}</q-item-label>
          <!-- <q-item-label v-else>Por favor, registre un apellido</q-item-label> -->
        </q-item-section>
      </q-item>

      <!-- <q-item class="q-ml-md">
        <q-item-section>
          <q-item-label caption>Fecha de nacimiento</q-item-label>
           <q-item-label>{{ }}</q-item-label> 
        </q-item-section>
      </q-item> -->

      <q-item class="q-ml-md">
        <q-item-section>
          <q-item-label caption>{{
            $t("Profile.personal-data.phone")
          }}</q-item-label>
          <q-item-label >{{
            user.phone
          }}</q-item-label>
          <!-- <q-item-label v-else>Por favor, registre un teléfono</q-item-label> -->
        </q-item-section>
      </q-item>
    </q-list>
  </q-card>
  <EditPersonalData v-model="dialog" />
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapState } from "vuex";

const EditPersonalData = defineAsyncComponent(() =>
  import("../editprofile/EditPersonalData.vue")
);

export default {
  components: {
    EditPersonalData,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
};
</script>

<style lang="scss" scoped>
.q-card {
    border-radius: 25px 25px 25px 0px;

}
</style>

